import React, { useState } from "react";
import { db } from './firebase-config'; // Import Firebase Firestore config
import { collection, addDoc } from "firebase/firestore"; // Import Firestore methods

export default function Book() {
  const [guestCount, setGuestCount] = useState(1);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    roomType: '',
    checkIn: '',
    checkOut: '',
    guests: 1
  });

  const handleGuestIncrement = () => {
    if (guestCount < 4) setGuestCount(prev => prev + 1);
  };

  const handleGuestDecrement = () => {
    if (guestCount > 1) setGuestCount(prev => prev - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(db, "bookings"), {
        ...formData,
        guests: guestCount,
        phone: Number(formData.phone),
        status: "occupied"
      });
      console.log("Booking added with ID: ", docRef.id);
      setFormData({
        fullName: '',
        email: '',
        phone: '',
        roomType: '',
        checkIn: '',
        checkOut: '',
        guests: 1
      });
      setGuestCount(1);
      alert("Room booked successfully!");
    } catch (e) {
      console.error("Error adding booking: ", e);
    }
  };

  return (
    <div className="container-fluid booking pb-5 wow fadeIn" data-wow-delay="0.1s">
      <div className="container">
        <div className="bg-white shadow" style={{ 
          padding: "40px",
          borderRadius: "15px",
          maxWidth: "800px",
          margin: "0 auto"
        }}>
          <h2 className="text-center mb-4" style={{ 
            color: "#2C3E50",
            fontSize: "2rem",
            fontWeight: "600",
            marginBottom: "30px !important"
          }}>
            Book Your Stay
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="row g-4">
              {/* Full Name */}
              <div className="col-12">
                <div className="form-floating">
                  <input
                    type="text"
                    name="fullName"
                    className="form-control custom-input"
                    id="fullName"
                    placeholder="Full Name"
                    value={formData.fullName}
                    onChange={handleChange}
                    required
                    style={{ height: "55px" }}
                  />
                  <label htmlFor="fullName" style={{ color: "#666" }}>Full Name</label>
                </div>
              </div>

              {/* Email and Phone */}
              <div className="col-md-6">
                <div className="form-floating">
                  <input
                    type="email"
                    name="email"
                    className="form-control custom-input"
                    id="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    style={{ height: "55px" }}
                  />
                  <label htmlFor="email" style={{ color: "#666" }}>Email Address</label>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-floating">
                  <input
                    type="tel"
                    name="phone"
                    className="form-control custom-input"
                    id="phone"
                    placeholder="Phone Number"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    style={{ height: "55px" }}
                  />
                  <label htmlFor="phone" style={{ color: "#666" }}>Phone Number</label>
                </div>
              </div>

              {/* Room Type */}
              <div className="col-12">
                <div className="form-floating">
                  <select
                    name="roomType"
                    className="form-select custom-input"
                    id="roomType"
                    value={formData.roomType}
                    onChange={handleChange}
                    required
                    style={{ height: "55px" }}
                  >
                    <option value="">Select a room type</option>
                    <option value="junior_suite">Mini Suite</option>
                    <option value="executive_suite">Executive Suite</option>
                    <option value="super_deluxe">Deluxe</option>
                  </select>
                  <label htmlFor="roomType" style={{ color: "#666" }}>Room Type</label>
                </div>
              </div>

              {/* Check In and Check Out */}
              <div className="col-md-6">
                <div className="form-floating">
                  <input
                    type="date"
                    name="checkIn"
                    className="form-control custom-input"
                    id="checkIn"
                    value={formData.checkIn}
                    onChange={handleChange}
                    required
                    style={{ height: "55px" }}
                  />
                  <label htmlFor="checkIn" style={{ color: "#666" }}>Check In Date</label>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-floating">
                  <input
                    type="date"
                    name="checkOut"
                    className="form-control custom-input"
                    id="checkOut"
                    value={formData.checkOut}
                    onChange={handleChange}
                    required
                    style={{ height: "55px" }}
                  />
                  <label htmlFor="checkOut" style={{ color: "#666" }}>Check Out Date</label>
                </div>
              </div>

              {/* Guest Counter */}
              <div className="col-12">
                <label className="form-label" style={{ 
                  color: "#2C3E50",
                  fontWeight: "500",
                  marginBottom: "10px"
                }}>
                  Number of Guests
                </label>
                <div className="d-flex align-items-center border rounded p-3" style={{
                  backgroundColor: "#f8f9fa"
                }}>
                  <button 
                    type="button" 
                    className="btn btn-outline-secondary"
                    style={{ 
                      width: "45px", 
                      height: "45px",
                      borderRadius: "8px",
                      fontSize: "1.2rem"
                    }}
                    onClick={handleGuestDecrement}
                  >
                    -
                  </button>
                  <span className="mx-4 fs-5" style={{ minWidth: "30px", textAlign: "center" }}>
                    {guestCount}
                  </span>
                  <button 
                    type="button" 
                    className="btn btn-outline-secondary"
                    style={{ 
                      width: "45px", 
                      height: "45px",
                      borderRadius: "8px",
                      fontSize: "1.2rem"
                    }}
                    onClick={handleGuestIncrement}
                  >
                    +
                  </button>
                </div>
              </div>

              {/* Submit Button */}
              <div className="col-12 mt-4">
                <button
                  type="submit"
                  className="btn w-100"
                  style={{ 
                    backgroundColor: "#E25217", 
                    color: "#fff",
                    fontSize: "1.1rem",
                    fontWeight: "500",
                    padding: "15px",
                    borderRadius: "8px",
                    transition: "all 0.3s ease",
                    border: "none",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
                  }}
                  onMouseOver={(e) => e.target.style.backgroundColor = "#c94812"}
                  onMouseOut={(e) => e.target.style.backgroundColor = "#E25217"}
                >
                  Book Now
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
