import React from "react";
import { team } from "../data/Data";
import Heading from "../common/Heading";

export default function Teams() {
  return (
    <>
      <section className='contact-img'>
        <Heading
          heading="Our Team"
          subtitle="Explore Our"
          title="Staffs"
        />
      </section>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
            {team.map((item, index) => (
              <div
                className="col-lg-3 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s" 
                key={index}
              >
                <div className="rounded shadow overflow-hidden">
                  <div className="text-center p-4 mt-3">
                    <h5 className="fw-bold mb-0">{item.name}</h5>
                    <small>{item.designation}</small>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
