import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import CommonHeading from "../common/CommonHeading";
import { facility, roomItems } from "../data/Data";
import { db } from './firebase-config';
import { collection, addDoc } from "firebase/firestore";

export default function Rooms({ isHomePage = false }) {
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);
  const [guestCount, setGuestCount] = useState(1);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    roomType: "",
    checkIn: "",
    checkOut: "",
    guestCount: 1,
    status: "occupied",
    price: "200"
  });

  const handleBookNowClick = (roomName, roomPrice) => {
    setFormData(prev => ({
      ...prev,
      roomType: roomName,
      price: roomPrice
    }));
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleGuestIncrement = () => {
    if (guestCount < 4) {
      setGuestCount(guestCount + 1);
    }
  };

  const handleGuestDecrement = () => {
    if (guestCount > 1) {
      setGuestCount(guestCount - 1);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const docRef = await addDoc(collection(db, "bookings"), {
        ...formData,
        guestCount: guestCount,
        phone: Number(formData.phone),
        createdAt: new Date()
      });
      
      console.log("Booking added with ID: ", docRef.id);
      setShowPopup(false);
      alert("Booking submitted successfully!");
      
      setFormData({
        fullName: "",
        email: "",
        phone: "",
        roomType: "",
        checkIn: "",
        checkOut: "",
        guestCount: 1,
        status: "occupied",
        price: "200"
      });
      setGuestCount(1);
      
    } catch (error) {
      console.error("Error adding booking: ", error);
      alert("Error submitting booking. Please try again.");
    }
  };

  return (
    <>
      <style>
        {`
          .popup-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
          }

          .popup-content {
            background-color: white;
            padding: 2rem;
            border-radius: 8px;
            width: 90%;
            max-width: 500px;
            position: relative;
          }

          .close-btn {
            position: absolute;
            top: 10px;
            right: 10px;
            border: none;
            background: none;
            font-size: 1.5rem;
            cursor: pointer;
          }

          .booking-form {
            margin-top: 1rem;
          }

          .guest-counter {
            display: flex;
            align-items: center;
            gap: 1rem;
          }

          .counter-btn {
            width: 30px;
            height: 30px;
            border: 1px solid #ddd;
            background: #f8f9fa;
            border-radius: 4px;
            cursor: pointer;
          }

          .counter-btn:hover {
            background: #e9ecef;
          }

          .guest-count {
            font-size: 1.1rem;
            min-width: 20px;
            text-align: center;
          }

          .date-inputs {
            display: flex;
            gap: 1rem;
          }

          .date-inputs > div {
            flex: 1;
          }
        `}
      </style>

      <div className="container-xxl py-5">
        <div className="container">
          {!isHomePage && (
            <CommonHeading
              heading="Our Rooms"
              title="Rooms"
              subtitle="Explore Our"
            />
          )}
          <div className="row g-4">
            {roomItems.map((item, key) => (
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s" key={key}>
                <div className="room-item shadow rounded overflow-hidden">
                  <div className="position-relative">
                    <img className="img-fluid" src={item.img} alt="img" />
                    <small className="position-absolute start-0 top-100 translate-middle-y text-white rounded py-1 px-3 ms-4" style={{ backgroundColor: "#E25217"}}>
                      {item.price}
                    </small>
                  </div>
                  <div className="p-4 mt-2">
                    <div className="d-flex justify-content-between mb-3">
                      <h5 className="mb-0">{item.name}</h5>
                      <div className="ps-2">{item.star}</div>
                    </div>
                    <div className="d-flex mb-3">
                      {facility.map((facilityItem, index) => (
                        <small className="border-end me-3 pe-3" key={index}>
                          {facilityItem.icon}
                          {facilityItem.quantity} {facilityItem.facility}
                        </small>
                      ))}
                    </div>
                    <p className="text-body mb-3">{item.description}</p>
                    <div className="d-flex justify-content-between">
                      <Link to={`/details/${item.id}`} className="btn btn-sm btn-primary rounded py-2 px-4 text-white text-decoration-none">
                        View Details
                      </Link>
                      <button
                        className="btn btn-sm btn-dark rounded py-2 px-4"
                        onClick={() => handleBookNowClick(item.name, item.price)}
                      >
                        {item.darkbtn}
                      </button>
                      <Link to="/reservation" className="btn btn-sm btn-secondary rounded py-2 px-4 text-white text-decoration-none">
                        Reserve
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button className="close-btn" onClick={closePopup}>×</button>
            <h3>Book a Room</h3>
            <form className="booking-form" onSubmit={handleSubmit}>
              <div className="mb-3">
                <input 
                  type="text" 
                  name="fullName"
                  className="form-control" 
                  placeholder="Full Name" 
                  value={formData.fullName}
                  onChange={handleChange}
                  required 
                />
              </div>
              <div className="mb-3">
                <input 
                  type="email" 
                  name="email"
                  className="form-control" 
                  placeholder="Email" 
                  value={formData.email}
                  onChange={handleChange}
                  required 
                />
              </div>
              <div className="mb-3">
                <input 
                  type="tel" 
                  name="phone"
                  className="form-control" 
                  placeholder="Phone Number" 
                  value={formData.phone}
                  onChange={handleChange}
                  required 
                />
              </div>
              <div className="mb-3">
                <input 
                  type="text" 
                  name="roomType"
                  className="form-control" 
                  value={formData.roomType}
                  readOnly
                  disabled
                />
              </div>
              <div className="mb-3 date-inputs">
                <div>
                  <label className="form-label">Check In</label>
                  <input 
                    type="date" 
                    name="checkIn"
                    className="form-control" 
                    value={formData.checkIn}
                    onChange={handleChange}
                    required 
                  />
                </div>
                <div>
                  <label className="form-label">Check Out</label>
                  <input 
                    type="date" 
                    name="checkOut"
                    className="form-control" 
                    value={formData.checkOut}
                    onChange={handleChange}
                    required 
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Number of Guests</label>
                <div className="guest-counter">
                  <button 
                    type="button" 
                    className="counter-btn"
                    onClick={handleGuestDecrement}
                  >
                    -
                  </button>
                  <span className="guest-count">{guestCount}</span>
                  <button 
                    type="button" 
                    className="counter-btn"
                    onClick={handleGuestIncrement}
                  >
                    +
                  </button>
                </div>
              </div>
              <button type="submit" className="btn btn-primary w-100">Submit Booking</button>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
