export const navList = [
  {
    id: 1,
    path: "/",
    text: "Home",
  },
  {
    id: 2,
    path: "/about",
    text: "About",
  },
  {
    id: 3,
    path: "/services",
    text: "Services",
  },
  {
    id: 4,
    path: "/rooms",
    text: "Rooms",
  },
  {
    id: 5,
    path: "/page",
    text: "Page",
    subItems: [
      {
        id: 51,
        path: "/booking",
        text: "Booking",
      },
      {
        id: 52,
        path: "/team",
        text: "Our Team",
      },
      {
        id: 53,
        path: "/testimonial",
        text: "Testimonial",
      },
    ],
  },
  {
    id: 6,
    path: "/contact",
    text: "Contact",
  },
  {
    id: 7,
    path: "/reservation",
    text: "Reservation",
  },
];
export const socialIcons = [
  {
    icon: <i className="fab fa-facebook-f"></i>,
  },
  {
    icon: <i className="fab fa-twitter"></i>,
  },
  {
    icon: <i className="fab fa-instagram"></i>,
  },
  {
    icon: <i className="fab fa-linkedin-in"></i>,
  },
  {
    icon: <i className="fab fa-youtube"></i>,
  },
];

export const carouselData = [
  {
    img: "../assets/img/carousel-1.jpg",
    title: "Welcome to Mungwi Park Gardens",
    subtitle: "Unforgettable Experiences",
    btn1: "Our Room",
    btn2: "Book Room",
  },
  {
    img: "../assets/img/carousel-2.jpg",
    title: "Where you can truly feel at home.",
    subtitle: "Natural Surrounding",
    btn1: "Our Room",
    btn2: "Book Room",
  },
];
export const about = [
  {
    icon: <i class="fa fa-hotel fa-2x text-primary mb-2"></i>,
    text: "Rooms",
    count: "35",
  },
  {
    icon: <i class="fa fa-users fa-2x text-primary mb-2"></i>,
    text: "Staffs",
    count: "20",
  },
  {
    icon: <i class="fa fa-users-cog fa-2x text-primary mb-2"></i>,
    text: "Clients",
    count: "10,321",
  },
];

export const services = [
  {
    id: 1,
    icon: <i class="fa fa-hotel fa-2x text-primary"></i>,
    name: "Rooms & Apartments",
    path: "rooms-and-apartments",
    discription: "Your description here...",
    fullDescription: `
      Luxury Accommodations
      - Spacious rooms with modern amenities
      - Premium bedding and furnishings
      - Private balconies with scenic views
      - 24/7 room service
    `,
    images: [
      
      "/assets/img/f.jpg",
      "/assets/img/g.jpg",
      "/assets/img/room-1.jpg",
      "/assets/img/room-2.jpg",
      "/assets/img/a.jpg",
      "/assets/img/b.jpg",
      "/assets/img/c.jpg",
      "/assets/img/d.jpg",
      "/assets/img/h.jpg",
      "/assets/img/e.jpg"
    ]
  },
  {
    id: 2,
    icon: <i class="fa fa-utensils fa-2x text-primary"></i>,
    name: "Bar & Restaurant",
    path: "bar-and-restaurant",
    discription: "Whether you're in the mood for a traditional meal or a taste of something new...",
    fullDescription: `Experience fine dining at its best in our restaurant:
    - Extensive menu featuring local and international cuisine
    - Full-service bar with premium spirits and wines
    - Professional bartenders and waitstaff
    - Indoor and outdoor seating available
    - Special dietary requirements accommodated
    - Private dining areas for special occasions
    - Regular food and wine tasting events`,
    images: ["../assets/img/res.JPG", "../assets/img/res1.JPG","../assets/img/m2.png"]
  },
  {
    id: 3,
    icon: <i class="fa fa-music fa-2x text-primary"></i>,
    name: "Music & Enjoyments",
    path: "music-and-entertainment",
    discription: "The perfect evening awaits at our fully stocked bar...",
    fullDescription: `Entertainment options that make every visit memorable:
    - Live band performances on weekends
    - Professional sound system
    - Dance floor
    - Regular themed nights
    - Karaoke sessions
    - Private party bookings available
    - Outdoor entertainment area`,
    images: ["../assets/img/entertainment-1.jpg", "../assets/img/entertainment-2.jpg"]
  },
  {
    id: 4,
    icon: <i class="fa fa-swimmer fa-2x text-primary"></i>,
    name: "Swimming Pool",
    path: "swimming-pool",
    discription: "Best pool experience for both children and adults...",
    fullDescription: `Our swimming pool facilities include:
    - Separate adult and children's pools
    - Trained lifeguards on duty
    - Pool bar service
    - Clean changing rooms and showers
    - Pool heating system
    - Regular water quality testing
    - Swimming lessons available
    - Pool-side loungers and umbrellas`,
    images: ["../assets/img/pool-1.jpg", "../assets/img/pool-2.jpg"]
  },
  {
    id: 5,
    icon: <i class="fa fa-glass-cheers fa-2x text-primary"></i>,
    name: "Conferencing, Event & Party",
    path: "events-and-conferencing",
    discription: "Your special moments deserve a special venue...",
    fullDescription: `Our event facilities and services include:
    - Multiple conference rooms of varying sizes
    - State-of-the-art audio-visual equipment
    - Professional event planning services
    - Customizable catering options
    - Wedding venue packages
    - Corporate event facilities
    - Dedicated event coordinator
    - Full decoration services`,
    images: [
      "../assets/img/img1.jpg",
      "../assets/img/img2.jpg",
      "../assets/img/img3.jpg",
      "../assets/img/img4.jpg",
      "../assets/img/img5.jpg",
      "../assets/img/img6.jpg",
      "../assets/img/img7.jpg",
      "../assets/img/img8.jpg"
    ]
  },
  {
    id: 6,
    icon: <i class="fa fa-child fa-2x text-primary"></i>,
    name: "Kids Play Park",
    path: "kids-play-park",
    discription: "Families are always welcome here...",
    fullDescription: `Our children's playground features:
    - Safe and modern play equipment
    - Supervised play areas
    - Indoor and outdoor activities
    - Educational play zones
    - Regular children's events
    - Birthday party packages
    - First aid trained staff
    - Age-appropriate play sections`,
    images: [
      "../assets/img/ui9.jpg",
      "../assets/img/ui10.jpg",
      "../assets/img/ui11.jpg",
      "../assets/img/ui12.jpg",
      "../assets/img/ui14.jpg",
      "../assets/img/ui14.jpg",
  
    ]
  },
  {
    id: 7,
    icon: <i class="fa fa-calendar-check fa-2x text-primary"></i>,
    name: "Bookings & Hiring",
    path: "bookings-and-hiring",
    discription: "Make your reservations for rooms, events, and equipment seamlessly...",
    fullDescription: `Our booking services include:
    - Online reservation system
    - Equipment rental services
    - Venue hiring options
    - Flexible payment plans
    - Group booking discounts
    - Corporate rates available
    - 24/7 booking assistance
    - Customizable packages`,
    images: ["../assets/img/booking-1.jpg", "../assets/img/hiring-1.jpg"]
  },
  {
    id: 8,
    icon: <i class="fa fa-concierge-bell fa-2x text-primary"></i>,
    name: "Our Catering Team",
    path: "catering-team",
    discription: "Offers quality, elegant and high standard service...",
    fullDescription: `Our professional catering services feature:
    - Experienced chefs and service staff
    - Customizable menu options
    - International cuisine expertise
    - Special dietary accommodation
    - Off-site catering available
    - Corporate lunch packages
    - Wedding catering specialists
    - Food safety certified team`,
    images: ["../assets/img/catering-1.jpg", "../assets/img/catering-2.jpg"]
  },
  {
    id: 9,
    icon: <i class="fa fa-paint-brush fa-2x text-primary"></i>,
    name: "Outside Décor",
    path: "outside-decor",
    discription: "Let us transform your outdoor events...",
    fullDescription: `Our décor services include:
    - Professional event styling
    - Custom theme creation
    - Outdoor lighting solutions
    - Floral arrangements
    - Furniture rental options
    - Setup and teardown service
    - Seasonal decorations
    - Event photography backdrops`,
    images: ["../assets/img/decor-1.jpg", "../assets/img/decor-2.jpg"]
  },
  {
    id: 10,
    icon: <i class="fa fa-chalkboard-teacher fa-2x text-primary"></i>,
    name: "Skills Institute",
    path: "skills-institute",
    discription: "Our Skills Institute offers a variety of courses...",
    fullDescription: `Our educational programs include:
    - Hospitality management courses
    - Culinary arts training
    - Customer service workshops
    - Event planning certification
    - Business skills development
    - Professional certification programs
    - Industry-experienced instructors
    - Practical hands-on training`,
    images: ["../assets/img/institute-1.jpg", "../assets/img/institute-2.jpg"]
  }
];
export const team = [
  {
    image: "../assets/img/team-3.jpg",
    name: "Victor Nyirenda",
    designation: "Director",
  },
  {
    image: "../assets/img/team-3.jpg",
    name: "Daison lungu",
    designation: "Events Manager",
  },
  {
    image: "../assets/img/team-3.jpg",
    name: "Mabvuto Nyirenda",
    designation: "Operation Manager",
  },
  
];

export const footerItem = [
  {
    id: 1,
    header: "Company",
    UnitItem: [
      {
        name: "About Us",
      },
      {
        name: "Contact Us",
      },
      {
        name: "Privacy Policy",
      },
      {
        name: "Terms & Condition",
      },
      {
        name: "Support",
      },
    ],
  },
  {
    id: 2,
    header: "Services",
    UnitItem: [
      {
        name: "Food & Restaurant",
      },
      {
        name: "Lodging Rooms",
      },
      {
        name: "Children Gaming park",
      },
      {
        name: "Event & Party",
      },
      {
        name: "swimming pool",
      },
    ],
  },
];

export const footerContact = [
  {
    icon: <i className="fa fa-map-marker-alt me-3"></i>,
    name: "Plot Number 90401 Chimutengo Road off Mungwi Road, Lusaka West, Chilanga.Po Box 38385",
  },
  {
    icon: <i className="fa fa-phone-alt me-3"></i>,
    name: "+260771407186 +2607619146971",
  },
  {
    icon: <i className="fa fa-envelope me-3"></i>,
    name: "Imfo@mungwiparkgardens.com",
  },
];

export const contact = [
  {
    icon: <i class="fa fa-phone text-primary me-2"></i>,
    title: "Booking",
    email: "+260771407186/+2607619146971+260957534616",
  },
  {
    icon: <i class="fa fa-envelope-open text-primary me-2"></i>,
    title: "Technical",
    email: "Imfo@mungwiparkgardens.com",
  },
  {
    icon: <i class="fa fa-envelope-open text-primary me-2"></i>,
    title: "General",
    email: "Po Box 38385",
  },
];
export const testimonial = [
  {
    description:
      "They have good services and their food test nice🙂",
    name: "Azariah W-Õràçlê",
    profession: "Profession",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-1.jpg",
  },
  {
    description:
      "I love this place it's really cool fresh air and free mind it's funny been there",
    name: "Felisha Kayz Angel",
    profession: "Profession",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-2.jpg",
  },
  {
    description:
      "Cold beer, good food and lot's of fresh air",
    name: "Matthew ",
    profession: "Profession",
    icon: (
      <i class="fa fa-quote-right fa-3x text-primary position-absolute end-0 bottom-0 me-4 mb-n1"></i>
    ),
    img: "../assets/img/testimonial-3.jpg",
  },
];

export const roomItems = [
  {
    id: 1,
    img: "../assets/img/room-1.jpg",
    price: "K400/night",
    name: "Deluxe",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "DSTV, Wi-Fi, swimming and work space.",
    yellowbtn: "View Detail",
    darkbtn: "book now",
  },

  {
    id:2,
    img: "../assets/img/room-2.jpg",
    price: "K550/night",
    name: "Executive Suite",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "Continental, DSTV, Wi-Fi, swimming and work space.",
    yellowbtn: "View Detail",
    darkbtn: "book now",
  },
  {
    id:3,
    img: "../assets/img/room-3.jpg",
    price: "k350/night",
    name: "Mini Suite",
    star: [
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
      <small class="fa fa-star text-primary"></small>,
    ],
    description:
      "DSTV, Wi-Fi, swimming.",
    yellowbtn: "View Detail",
    darkbtn: "book now",
  },
];

export const facility = [
  {
    icon: <i class="fa fa-bed text-primary me-2"></i>,
    quantity: 1,
    facility: "bed",
  },
  {
    icon: <i class="fa fa-bath text-primary me-2"></i>,
    quantity: 1,
    facility: "bath",
  },
  {
    icon: <i class="fa fa-wifi text-primary me-2"></i>,
    facility: "Wifi",
  },
];
