import React from "react";
import "./css/style.css";
import "./css/bootstrap.min.css";
import "./css/animate.css";
import "./css/animate.min.css";
import "./App.css";
import Header from "./components/common/Header";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import {
  Home,
  Booking,
  AboutUs,
  PageNotFound,
  Room,
  Services,
  Team,
} from "./pages/index";
import Footer from "./components/common/Footer";
import Rooms from "./components/home/Rooms";
import RoomDetails from "./components/home/RoomDetails";
import Reservation from "./components/home/Reservation";
import ServiceDetailPage from "./pages/ServiceDetailPage";
import ScrollToTop from './components/common/ScrollToTop';
import Contact from "./pages/ContactPage";
import TestimonialPage from "./pages/TestimonialPage";


export default function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/team" element={<Team />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/:servicePath" element={<ServiceDetailPage />} />
          <Route path="/reservation" element={<Reservation />} />
          <Route path="/details/:id" element={<RoomDetails />} />
          <Route path="/rooms" element={<Rooms />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/testimonial" element={<TestimonialPage />} />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}
