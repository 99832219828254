import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { roomItems } from "../data/Data";
import BookingPopup from './BookingPopup';

export default function RoomDetails() {
  const { id } = useParams();
  const room = roomItems.find((item) => item.id === parseInt(id));

  const [isBookingOpen, setIsBookingOpen] = useState(false);

  if (!room) {
    return <div style={{ textAlign: "center", color: "red", fontSize: "18px" }}>Room not found</div>;
  }

  const styles = {
    container: {
      maxWidth: "1200px",
      margin: "0 auto",
      padding: "20px",
      fontFamily: "'Arial', sans-serif",
    },
    header: {
      textAlign: "center",
      marginBottom: "20px",
    },
    title: {
      fontSize: "32px",
      fontWeight: "bold",
      color: "#333",
    },
    description: {
      fontSize: "16px",
      color: "#555",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr 2fr 1fr",
      gap: "10px",
      alignItems: "center",
      marginBottom: "30px",
    },
    smallImage: {
      width: "100%",
      height: "100%",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      objectFit: "cover",
    },
    largeImage: {
      width: "100%",
      height: "100%",
      borderRadius: "10px",
      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
      objectFit: "cover",
    },
    details: {
      marginTop: "20px",
    },
    detailItem: {
      fontSize: "18px",
      color: "#333",
      marginBottom: "10px",
    },
    facilityList: {
      listStyle: "none",
      padding: "0",
    },
    facilityItem: {
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      color: "#555",
    },
    facilityIcon: {
      fontSize: "20px",
      marginRight: "8px",
      color: "#666",
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px",
    },
    button: {
      backgroundColor: "#EB4D0FFF",
      color: "#fff",
      padding: "12px 20px",
      fontSize: "16px",
      fontWeight: "bold",
      borderRadius: "5px",
      border: "none",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
    },
    buttonHover: {
      backgroundColor: "#F05A14FF",
    },
  };

  return (
    <div style={styles.container}>
      {/* Header */}
      <div style={styles.header}>
        <h1 style={styles.title}>{room.name}</h1>
        <p style={styles.description}>{room.description}</p>
      </div>

      {/* Image Grid */}
      <div style={styles.grid}>
        <img style={styles.smallImage} src={room.img} alt={`${room.name} small`} />
        <img style={styles.largeImage} src={room.img} alt={`${room.name} large`} />
        <img style={styles.smallImage} src={room.img} alt={`${room.name} small`} />
      </div>

      {/* Room Features and Amenities */}
      <div style={styles.details}>
        <h2 style={{ fontSize: "24px", color: "#333", marginBottom: "20px" }}>Room Features & Amenities</h2>
        
        <div style={{ 
          display: "grid", 
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))", 
          gap: "20px",
          padding: "20px",
          backgroundColor: "#f9f9f9",
          borderRadius: "10px"
        }}>
          {/* Room Capacity */}
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <i className="fas fa-users" style={{ color: "#E25217", fontSize: "24px" }}></i>
            <span>Guests</span>2
          </div>

          {/* Room Size */}
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <i className="fas fa-ruler-combined" style={{ color: "#E25217", fontSize: "24px" }}></i>
            <span>35 Feets Size</span>
          </div>

          {/* Connecting Rooms */}
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <i className="fas fa-door-open" style={{ color: "#E25217", fontSize: "24px" }}></i>
            <span>Connecting Rooms</span>
          </div>

          {/* Bed Type */}
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <i className="fas fa-bed" style={{ color: "#E25217", fontSize: "24px" }}></i>
            <span>1 King Bed</span>
          </div>

          {/* Cable TV */}
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <i className="fas fa-tv" style={{ color: "#E25217", fontSize: "24px" }}></i>
            <span>Cable TV</span>
          </div>

          {/* Shower */}
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <i className="fas fa-shower" style={{ color: "#E25217", fontSize: "24px" }}></i>
            <span>Shower</span>
          </div>

          {/* Safebox */}
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <i className="fas fa-lock" style={{ color: "#E25217", fontSize: "24px" }}></i>
            <span>Safebox</span>
          </div>

          {/* WiFi */}
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <i className="fas fa-wifi" style={{ color: "#E25217", fontSize: "24px" }}></i>
            <span>Free WiFi</span>
          </div>

          {/* Work Desk */}
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <i className="fas fa-desktop" style={{ color: "#E25217", fontSize: "24px" }}></i>
            <span>Work Desk</span>
          </div>

          {/* Balcony */}
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <i className="fas fa-door-open" style={{ color: "#E25217", fontSize: "24px" }}></i>
            <span>Balcony</span>
          </div>

          {/* Bathtub */}
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <i className="fas fa-bath" style={{ color: "#E25217", fontSize: "24px" }}></i>
            <span>Bathtub</span>
          </div>

          {/* City View */}
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <i className="fas fa-city" style={{ color: "#E25217", fontSize: "24px" }}></i>
            <span>City View</span>
          </div>
        </div>

        {/* Book Now Button */}
        <div style={styles.buttonContainer}>
          <button 
            style={styles.button}
            onMouseOver={(e) => e.target.style.backgroundColor = "#c94812"}
            onMouseOut={(e) => e.target.style.backgroundColor = "#E25217"}
            onClick={() => setIsBookingOpen(true)}
          >
            Book Now
          </button>
        </div>
      </div>

      <BookingPopup 
        isOpen={isBookingOpen}
        onClose={() => setIsBookingOpen(false)}
        room={room}
      />
    </div>
  );
}
