import React, { useState } from "react";
import CommonHeading from "../common/CommonHeading";
import Heading from "../common/Heading";
import { db } from './firebase-config'; 
import { collection, addDoc } from "firebase/firestore"; 

const Reservation = () => {
  const [date, setDate] = useState({ start: "", end: "" });
  const [adults, setAdults] = useState(0);  
  const [children, setChildren] = useState(0);  
  const [rooms, setRooms] = useState(0);  
  const [name, setName] = useState("");  
  const [email, setEmail] = useState("");  
  const [message, setMessage] = useState("");  
  const [selectedRoom, setSelectedRoom] = useState(""); 

  const handleIncrement = (setState, value) => {
    setState(value + 1);
  };

  const handleDecrement = (setState, value) => {
    if (value > 0) {
      setState(value - 1);
    }
  };

  const validateDates = () => {
    const checkInDate = new Date(date.start);
    const checkOutDate = new Date(date.end);

    if (checkInDate >= checkOutDate) {
      alert("Check-out date must be later than check-in date.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateDates()) return;

    try {
      await addDoc(collection(db, "reservations"), {
        adults: String(adults),  
        children: String(children),  
        checkin: date.start,
        checkout: date.end,
        email,
        message,
        name,
        rooms: String(rooms),  
        selectedRoom,  
      });

      alert("Reservation submitted successfully!");
      setDate({ start: "", end: "" });
      setAdults(0);  
      setChildren(0);  
      setRooms(0);  
      setName("");  
      setEmail("");  
      setMessage("");  
      setSelectedRoom("");  
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("Failed to submit reservation. Please try again.");
    }
  };

  return (
    <div className="font-sans mx-auto max-w-4xl">
      <Heading />
      <CommonHeading />
      <header className="text-center mb-9 py-9 bg-gray-250 w-full">
        <h1 className="text-3xl font-bold">Reservation</h1>
        <p className="text-lg text-gray-600">
          Ready to elevate your travel experience? Reserve your room now and
          step into a world of comfort and luxury. Your perfect stay is just a
          click away.
        </p>
      </header>

      <div style={styles.container}>
        <form style={styles.form} onSubmit={handleSubmit}>
          <div style={styles.formSection}>
            <div style={styles.formGroup}>
              <label>Check-In Date</label>
              <input
                type="date"
                value={date.start}
                onChange={(e) => setDate({ ...date, start: e.target.value })}
                style={styles.input}
                required
              />
            </div>
            <div style={styles.formGroup}>
              <label>Check-Out Date</label>
              <input
                type="date"
                value={date.end}
                onChange={(e) => setDate({ ...date, end: e.target.value })}
                style={styles.input}
                required
              />
            </div>
          </div>

          <div style={styles.formSection}>
            <div style={styles.formGroup}>
              <label>Adults</label>
              <div style={styles.numberInput}>
                <button
                  type="button"
                  style={styles.button}
                  onClick={() => handleDecrement(setAdults, adults)}
                >
                  -
                </button>
                <span>{adults}</span>
                <button
                  type="button"
                  style={styles.button}
                  onClick={() => handleIncrement(setAdults, adults)}
                >
                  +
                </button>
              </div>
            </div>
            <div style={styles.formGroup}>
              <label>Children</label>
              <div style={styles.numberInput}>
                <button
                  type="button"
                  style={styles.button}
                  onClick={() => handleDecrement(setChildren, children)}
                >
                  -
                </button>
                <span>{children}</span>
                <button
                  type="button"
                  style={styles.button}
                  onClick={() => handleIncrement(setChildren, children)}
                >
                  +
                </button>
              </div>
            </div>
            <div style={styles.formGroup}>
              <label>Rooms</label>
              <div style={styles.numberInput}>
                <button
                  type="button"
                  style={styles.button}
                  onClick={() => handleDecrement(setRooms, rooms)}
                >
                  -
                </button>
                <span>{rooms}</span>
                <button
                  type="button"
                  style={styles.button}
                  onClick={() => handleIncrement(setRooms, rooms)}
                >
                  +
                </button>
              </div>
            </div>
          </div>

          <div style={styles.formSection}>
            <div style={{ ...styles.formGroup, flex: "1 1 100%" }}>
              <label>Select Room</label>
              <select 
                style={styles.select} 
                value={selectedRoom} 
                onChange={(e) => setSelectedRoom(e.target.value)} 
                required
              >
                <option value="">Select a Room</option>
                <option value="standard">Standard Executive - K550/night</option>
                <option value="deluxe">Deluxe - K400/night</option>
                <option value="suite">Mini Suite - K350/night</option>
              </select>
            </div>
          </div>

          <div style={styles.formSection}>
            <div style={styles.formGroup}>
              <label>Your Name</label>
              <input
                type="text"
                placeholder="Enter your name"
                style={styles.input}
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div style={styles.formGroup}>
              <label>Your Email</label>
              <input
                type="email"
                placeholder="Enter your email"
                style={styles.input}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div style={{ ...styles.formGroup, flex: "1 1 100%" }}>
              <label>Your Message</label>
              <textarea
                placeholder="Enter additional information (optional)"
                style={styles.textarea}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
          </div>

          <button type="submit" style={styles.submitButton}>
            Submit Reservation
          </button>
        </form>
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    margin: "0 auto",
    maxWidth: "800px",
    padding: "20px",
  },
  form: {
    backgroundColor: "#fff",
    borderRadius: "5px",
    padding: "20px",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
  },
  formSection: {
    display: "flex",
    justifyContent: "space-between",
    gap: "15px",
    marginBottom: "20px",
  },
  formGroup: {
    flex: "1 1 30%",
    display: "flex",
    flexDirection: "column",
  },
  input: {
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontSize: "1rem",
  },
  select: {
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontSize: "1rem",
  },
  numberInput: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  button: {
    backgroundColor: "#E25217",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    fontSize: "1rem",
    cursor: "pointer",
    padding: "5px 10px",
  },
  textarea: {
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontSize: "1rem",
    height: "100px",
  },
  submitButton: {
    backgroundColor: "#E25217",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    fontSize: "1rem",
    padding: "15px",
    cursor: "pointer",
    width: "100%",
  },
};

export default Reservation;
