// src/components/home/BookingPopup.js
import React, { useState } from 'react';
import './BookingPopup.css';

export default function BookingPopup({ isOpen, onClose, room }) {
  const [guestCount, setGuestCount] = useState(1);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    roomType: room?.name || '',
    checkIn: '',
    checkOut: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleGuestIncrement = () => {
    if (guestCount < 4) setGuestCount(prev => prev + 1);
  };

  const handleGuestDecrement = () => {
    if (guestCount > 1) setGuestCount(prev => prev - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log({ ...formData, guests: guestCount });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-btn" onClick={onClose}>×</button>
        <h3>Book a Room</h3>
        <form className="booking-form" onSubmit={handleSubmit}>
          <div className="mb-3">
            <input 
              type="text" 
              name="fullName"
              className="form-control" 
              placeholder="Full Name" 
              value={formData.fullName}
              onChange={handleChange}
              required 
            />
          </div>
          <div className="mb-3">
            <input 
              type="email" 
              name="email"
              className="form-control" 
              placeholder="Email" 
              value={formData.email}
              onChange={handleChange}
              required 
            />
          </div>
          <div className="mb-3">
            <input 
              type="tel" 
              name="phone"
              className="form-control" 
              placeholder="Phone Number" 
              value={formData.phone}
              onChange={handleChange}
              required 
            />
          </div>
          <div className="mb-3">
            <input 
              type="text" 
              name="roomType"
              className="form-control" 
              value={formData.roomType}
              readOnly
              disabled
            />
          </div>
          <div className="mb-3 date-inputs">
            <div>
              <label className="form-label">Check In</label>
              <input 
                type="date" 
                name="checkIn"
                className="form-control" 
                value={formData.checkIn}
                onChange={handleChange}
                required 
              />
            </div>
            <div>
              <label className="form-label">Check Out</label>
              <input 
                type="date" 
                name="checkOut"
                className="form-control" 
                value={formData.checkOut}
                onChange={handleChange}
                required 
              />
            </div>
          </div>
          <div className="mb-3">
            <label className="form-label">Number of Guests</label>
            <div className="guest-counter">
              <button 
                type="button" 
                className="counter-btn"
                onClick={handleGuestDecrement}
              >
                -
              </button>
              <span className="guest-count">{guestCount}</span>
              <button 
                type="button" 
                className="counter-btn"
                onClick={handleGuestIncrement}
              >
                +
              </button>
            </div>
          </div>
          <button type="submit" className="btn btn-primary w-100">Submit Booking</button>
        </form>
      </div>
    </div>
  );
}